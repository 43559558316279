<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Arqueo de Caja - Nuevo</strong>
          </CCardHeader>
          <CCardBody>

                <div class="w-100" v-if="state == 1">

                    <b-form id="Form" @submit.prevent="ValidateAdd">
                      <b-row>
                          <b-col md="2">
                              <b-form-group label="Codigo:">
                              <b-form-input type="text" class="text-center" readonly v-model="newbox.number" ></b-form-input>
                              <small v-if="newerrors.id_correlative" class="form-text text-danger">Ingrese un código</small >
                              </b-form-group>
                          </b-col>

                          <b-col md="2">
                              <b-form-group label="Fecha Inicio:">
                              <b-form-input disabled :min="min_date" type="date" class="text-center"  v-model="newbox.start_date" ></b-form-input>
                              </b-form-group>
                          </b-col>

                          <b-col md="2">
                              <b-form-group label="Saldo Inicial:">
                              <b-form-input type="number" step="any" class="text-right" v-model="newbox.initial_balance" ></b-form-input>
                              <small v-if="newerrors.initial_balance" class="form-text text-danger">Ingrese un monto</small >
                              </b-form-group>
                          </b-col>


                          <b-col md="6">
                              <b-form-group label="Observación :">
                              <b-form-input  v-model="newbox.observation" ></b-form-input>
                              </b-form-group>
                          </b-col>

                          <b-col md="5"></b-col>
                          <b-col md="2">
                              <b-form-group label=".">
                              <b-button type="submit" class="form-control btn btn-primary" variant="primary">INICIAR</b-button>
                              </b-form-group>
                          </b-col>
                      </b-row>
                    </b-form>
                </div>

                <div class="w-100" v-if="state == 2">

                    <b-form id="Form" autocomplete="off" @submit.prevent="Validate">
                            <b-row>
                                <b-col md="2">
                                    <b-form-group label="Codigo:">
                                    <b-form-input type="text" class="text-center" readonly v-model="box.number" ></b-form-input>
                                    </b-form-group>
                                </b-col>

                                <b-col md="2">
                                    <b-form-group label="Fecha Inicio:">
                                    <b-form-input readonly type="text" class="text-center" v-model="box.start_date" ></b-form-input>
                                    </b-form-group>
                                </b-col>

                                <b-col md="2">
                                    <b-form-group label="Fecha Final:">
                                    <b-form-input readonly type="text" class="text-center" v-model="box.end_date" ></b-form-input>
                                    </b-form-group>
                                </b-col>

                                <b-col md="2">
                                    <b-form-group label="Usuario:">
                                    <b-form-input type="text" class="text-center" readonly v-model="box.user" ></b-form-input>
                                    </b-form-group>
                                </b-col>

                                <b-col md="2">
                                    <b-form-group label="Estado:">
                                    <b-form-select :options="states" disabled v-model="box.state" ></b-form-select>
                                    </b-form-group>
                                </b-col>

                                <b-col md="2">
                                    <b-form-group label="Saldo Inicial:">
                                    <b-form-input type="text" class="text-right" readonly v-model="box.initial_balance" ></b-form-input>
                                    </b-form-group>
                                </b-col>

                                <b-col md="12">
                                  <b-form-group>
                                    <label class="control-label">Observación: </label>
                                    <b-form-textarea v-model="box.observation" rows="2"></b-form-textarea>                 
                                  </b-form-group>
                                </b-col>

                                <b-col md="6">
                                   <div class="table-responsive">
                                      <table class="table table-hover table-bordered">
                                        <thead>
                                          <tr class="bg-success">
                                            <th width="70%" class="text-center">Modulo</th>
                                            <th width="30%" class="text-right">Monto</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr class="bg-info">
                                            <td class="text-left pl-1">Pagos</td>
                                            <td class="text-right">{{ box.payments }}</td>
                                          </tr>
                                          <tr class="bg-info">
                                            <td class="text-left pl-1">Aportes</td>
                                            <td class="text-right">{{ box.contributions }}</td>
                                          </tr>
                                          <tr class="bg-info">
                                            <td class="text-left pl-1">Pagos Anticipados</td>
                                            <td class="text-right">{{ box.advance_payments }}</td>
                                          </tr>
                                          <tr class="bg-info">
                                            <td class="text-left pl-1">Egresos</td>
                                            <td class="text-right">{{ box.expenses }}</td>
                                          </tr>
                                          <tr class="bg-info">
                                            <td class="text-left pl-1">Otros</td>
                                            <td class="text-right">{{ box.others }}</td>
                                          </tr>

                                        </tbody>

                                      </table>
                                   </div>
                                </b-col>

                                <b-col md="6">
                                   <div class="table-responsive">
                                      <table class="table table-hover table-bordered">
                                        <thead>
                                          <tr class="bg-success">
                                            <th width="70%" class="text-center">Medio de Pago</th>
                                            <th width="30%" class="text-right">Monto</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr class="bg-info">
                                            <td class="text-left pl-1">Efectivo</td>
                                            <td class="text-right">{{ box.cash }}</td>
                                          </tr>
                                          <tr class="bg-info">
                                            <td class="text-left pl-1">Cheque</td>
                                            <td class="text-right">{{ box.check }}</td>
                                          </tr>
                                          <tr class="bg-info">
                                            <td class="text-left pl-1">Deposito de Cuenta</td>
                                            <td class="text-right">{{ box.deposit_account }}</td>
                                          </tr>
                                          <tr class="bg-info">
                                            <td class="text-left pl-1">Tranferencia de Fondos</td>
                                            <td class="text-right">{{ box.transfer_funds }}</td>
                                          </tr>
                                          <tr class="bg-success">
                                            <td class="text-left pl-1">Saldo Final</td>
                                            <td class="text-right">{{ box.final_balance }}</td>
                                          </tr>
                                        </tbody>

                                      </table>
                                   </div>
                                </b-col>

               
                                <b-col md="3"></b-col>
                                <b-col md="2">
                                    <b-button type="button" @click="ModalBoxSummaryShow" class="form-control" variant="info">Resumen de Caja</b-button>
                                </b-col>
                                <b-col md="2">
                                    <b-button type="button" @click="ExportExcel" class="form-control" variant="success">Excel</b-button>
                                </b-col>
                                <b-col md="2">
                                    <b-button type="submit" class="form-control" variant="primary">Cerrar Caja</b-button>
                                </b-col>
                            </b-row>
                    </b-form>

                </div> 
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>


    <BoxSummary/>
  </div>
</template>

<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
var moment = require("moment");
import EventBus from "@/assets/js/EventBus";
import BoxSummary from './components/Summary'
export default {
  name: "CashAdd",
  components:{
    BoxSummary,
  },
  data() {
    return {
      module: "Box",
      role: 2,
      state: 0,
      min_date:'',
      //new
      newbox: {

          id_box : '',
          id_user : '',
          number : '',
          start_date : moment(new Date()).local().format("YYYY-MM-DD"),
          end_date : moment(new Date()).local().format("YYYY-MM-DD"),
          observation : '',
          initial_balance : (0).toFixed(2),
          payments : (0).toFixed(2),
          contributions : (0).toFixed(2),
          advance_payments : (0).toFixed(2),
          expenses : (0).toFixed(2),
          others : (0).toFixed(2),

          cash : (0).toFixed(2),
          check : (0).toFixed(2),
          deposit_account : (0).toFixed(2),
          transfer_funds : (0).toFixed(2),

          final_balance : (0).toFixed(2),
          state : '1',


      },
      newerrors: {
        id_correlative: false,
        initial_balance: false,
        initial_balance_usd: false,
      },
      validate_add: false,

      states:[
        {value:'1',text:'Pendiente'},
        {value:'2',text:'Finalizado'},
        {value:'0',text:'Anulado'},
      ],





      box: {
          id_box : '',
          id_user : '',
          number : '',
          start_date : moment(new Date()).local().format("YYYY-MM-DD"),
          end_date : moment(new Date()).local().format("YYYY-MM-DD"),
          observation : '',
          initial_balance : (0).toFixed(2),
          payments : (0).toFixed(2),
          contributions : (0).toFixed(2),
          advance_payments : (0).toFixed(2),
          expenses : (0).toFixed(2),
          others : (0).toFixed(2),
          cash : (0).toFixed(2),
          check : (0).toFixed(2),
          deposit_account : (0).toFixed(2),
          transfer_funds : (0).toFixed(2),
          final_balance : (0).toFixed(2),
          state : '',
      },
      //errors
      errors: {
        id_correlative: false,
        initial_balance: false,
      },
      
      validate: false,


    };
  },
  mounted() {
    this.ValidateOpenBox();
  },
  methods: {
    ValidateOpenBox,

    GetCorrelative,
    Addcash,
    ValidateAdd,
    ExportExcel,
    ExportPDF,

    CalculateBox,
    CloseBox,
    Validate,

    ModalBoxSummaryShow,

  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  
  },
};
function ModalBoxSummaryShow() {

  let data = {
    role: this.role,
    id_box: this.box.id_box,
  };
  EventBus.$emit('ModalBoxSummaryShow',data);
  
}

function ValidateOpenBox() {

  let me = this;
  let url = this.url_base + "box/validate-open-box";
  let data = {
    id_user : me.user.id_user
  };
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {token: this.token,module: this.module,role: this.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.box.id_box = response.data.result.id_box;
        me.box.number = response.data.result.number;
        me.box.id_user = response.data.result.id_user;
        me.box.id_correlative = response.data.result.id_correlative;
        me.box.code = response.data.result.code;
        me.box.start_date = response.data.result.start_date;
        me.box.end_date = moment(new Date()).local().format("YYYY-MM-DD");
        me.box.observation = response.data.result.observation;
        me.box.state = response.data.result.state;
        me.box.user = response.data.result.user;
          
        me.state = 2;
        me.CalculateBox();
      }else{
        me.state = 1;
        me.GetCorrelative();
      }
    })
}

function GetCorrelative() {
  let me = this;
  let url = this.url_base + "get-correlative/Box";
  axios({
    method: "GET",
    url: url,
    headers: {token: this.token,module: this.module,role: this.role},
  })
  .then(function (response) {
    if (response.data.status == 200) {
      me.newbox.id_correlative = response.data.result.id_correlative;
      me.newbox.number = response.data.result.number;
    } 
  })
}

function ValidateAdd() {
  this.newerrors.id_correlative = this.newbox.id_correlative.length == 0 ? true : false;
  this.newerrors.initial_balance = this.newbox.initial_balance.length == 0 ? true : false;


  if (this.newerrors.id_correlative) { this.validate_add = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.newerrors.initial_balance) { this.validate_add = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }


  let me = this;
  Swal.fire({
    title: "Esta seguro de inicar caja ?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.Addcash();
    }
  });

}

function Addcash() {

  let me = this;
  me.newbox.id_user = me.user.id_user;
  let url = me.url_base + "box/add";
  let data = me.newbox;

  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {"Content-Type": "application/json", token: me.token, module: me.module, role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 201) {
        Swal.fire({ icon: 'success', text: response.data.message , timer: 3000,})
        me.ValidateOpenBox();
      } else {
        Swal.fire({ icon: 'error', text: response.data.message , timer: 3000,})
      }
    })
}


function CalculateBox() {
  let me = this;
  let url = this.url_base + "box/calculate-box";
  let data = {
    id_box : me.box.id_box,
    end_date : me.box.end_date,
  }
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {token: this.token, module: this.module, role: this.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {

          me.box.id_box = response.data.result.id_box;
          me.box.id_user = response.data.result.id_user;
          me.box.number = response.data.result.number;
          me.box.start_date = response.data.result.start_date;
          me.box.end_date = response.data.result.end_date;
          me.box.observation = response.data.result.observation;
          me.box.initial_balance = response.data.result.initial_balance;
          me.box.payments = response.data.result.payments;
          me.box.contributions = response.data.result.contributions;
          me.box.advance_payments = response.data.result.advance_payments;
          me.box.expenses = response.data.result.expenses;
          me.box.others = response.data.result.others;

          me.box.cash = response.data.result.cash;
          me.box.check = response.data.result.check;
          me.box.deposit_account = response.data.result.deposit_account;
          me.box.transfer_funds = response.data.result.transfer_funds;

          me.box.final_balance = response.data.result.final_balance;
          me.box.state = response.data.result.state;

      } 
    })
}
















function ExportExcel() {

  let me = this;
  let url = this.url_base + "box-export-excel/"+this.box.id_box;
  window.open(url,'_blank');
}

function ExportPDF() {
  let me = this;
  let url = this.url_base + "box-export-pdf/"+this.box.id_box;
  window.open(url,'_blank');
}


////STATE 1







// STATE 2
function CloseBox() {
  let me = this;
  let url = me.url_base + "box/close";
  let data = me.box;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {"Content-Type": "application/json", token: me.token,module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        Swal.fire({ icon: 'success', text: response.data.message , timer: 3000,})
        me.ValidateOpenBox();
      } else {
        Swal.fire({ icon: 'error', text: response.data.message , timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

function Validate() {
  this.errors.id_box = this.box.id_box.length == 0 ? true : false;
  this.errors.end_date = this.box.end_date.length == 0 ? true : false;

  if (this.errors.id_box) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.end_date) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }

  let me = this;
  Swal.fire({
    title: 'Esta seguro de cerrar la caja?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, Estoy de Acuerdo!'
  }).then((result) => {
    if (result.isConfirmed) {
      me.CloseBox();
    }
  })


}
</script>
